/* You can add global styles to this file, and also import other style files */

@import 'assets-path';

@import './shared-style/breakpoints';

@font-face {
	font-family: 'Montserrat';
	src: url('#{$assetsPath}assets/fonts/montserrat-regular.ttf') format('truetype'),
		url('#{$assetsPath}assets/fonts/montserrat-regular.woff') format('woff'),
		url('#{$assetsPath}assets/fonts/montserrat-regular.woff2') format('woff');
	font-weight: 400;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$assetsPath}assets/fonts/montserrat-semi-bold.ttf') format('truetype'),
		url('#{$assetsPath}assets/fonts/montserrat-semi-bold.woff') format('woff'),
		url('#{$assetsPath}assets/fonts/montserrat-semi-bold.woff2') format('woff');
	font-weight: 600;
}

@font-face {
	font-family: 'Montserrat';
	src: url('#{$assetsPath}assets/fonts/montserrat-bold.ttf') format('truetype'),
		url('#{$assetsPath}assets/fonts/montserrat-bold.woff') format('woff'),
		url('#{$assetsPath}assets/fonts/montserrat-bold.woff2') format('woff');
	font-weight: 700;
}

*, *:before, *:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

html, body {
	height: 100%;
}

html {
	font-size: 62.5%;
}

body {
	padding: 0;
	margin: 0;

	font-size: 1.6rem;
}

h1 {
	font-size: 3.2rem;
}

button {
	margin: 0;
	font-family: inherit;

	position: relative;
	font-weight: 700;
	font-family: inherit;
	font-size: inherit;
	padding: 1rem 3rem;
	border-radius: 30px;
	cursor: pointer;
	outline: none;
	margin: 0.1em 0.5rem;
	transition: background-color 250ms, opacity 250ms;

	&:disabled {
		opacity: 0.5;
		cursor: default;
	}

	&.is-loading {
		span {
			opacity: 0;
		}

		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 2rem;
			height: 2rem;
			background-image: url('#{$assetsPath}assets/img/loading.svg');
			background-position: center;
			background-size: 100%;
			background-repeat: no-repeat;

			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			animation: btn-loader 2s linear infinite;
		}

		@keyframes btn-loader {
			100% {
				transform: translate(-50%, -50%) rotate(360deg);
			}
		}
	}

}

button.default-btn {
	background-color: #ffb74d;
	color: #fff;
	border: 2px solid #ffa000;

	&:hover:not([disabled]) {
		// border-color: #aaa;
		background-color: #ffa000;
	}
}

button.secondary-btn {
	background-color: #acacac;
	color: #fff;
	border: 2px solid #dfdfdf;

	&:hover:not([disabled]) {
		// border-color: #aaa;
		background-color: #dfdfdf;
	}
}

input {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	padding: 0.8rem;
}

.ngx-datatable.material {
	box-shadow: 0 0 7px #acacac !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
	background-color: #444 !important;
	color: #fff !important;
	padding: 0.5rem 1.2rem !important;
	font-size: 14px !important;
	font-weight: 600 !important;
}

.ngx-datatable.material .datatable-body .progress-linear .container {
	background-color: #fbdbab !important;
}

.ngx-datatable.material .datatable-body .progress-linear .container .bar {
	background-color: #ffb74d !important;
}

@media print {
	@page
	{
		margin: 0mm;
	}

	ra-app {
		> * {
			opacity: 0;
		}

		ra-print-content, ra-print-draw-stats, ra-print-sales-stats {
			opacity: 1;			
		}

		ra-print-draw-stats {
			datatable-body-cell-label {
				font-size: 11px;
			}
		}
	}

	.page-break {
		display: block;
		page-break-before: always;
		break-before: always;
	}
}

@media only screen and (max-width: $tablet-breakpoint) {
	body {
		font-size: 2.2rem;
	}
}
